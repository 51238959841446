import React from 'react';
import GAScript from './GoogleAnalytics';
import SegmentScript from './SegmentAnalytics';
import NextDoor from './NextDoor';

const Analytics = () => (
  <>
    <GAScript />
    <SegmentScript />
    <NextDoor />
    {/* Puls and Handy do not use cookie consent. We should have a seperate page */}
    {/* <OneTrustScript /> */}
  </>
);

export const BodyAnalytics = () => (
  <>
    <script
      data-partytown-config
      dangerouslySetInnerHTML={{
        __html: `
                partytown = {
                  lib: "/_next/static/~partytown/",
                  forward: ["gtag"]           
                };
        `,
      }}
    />
  </>
);

export default Analytics;
